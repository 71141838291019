"use client";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";

import styles from "../auth.module.css";
import { situations, objectifs } from "../../../constants";
import { Input, Button, Select as RSelect } from "../../../components";
import { useStore } from "../../../store";
import Select from "react-select";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./style.css";

type CountryType = {
  value: string;
  label: string;
  iso: string;
};

function CompleteRegister() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const options = [
    { value: 1, label: "Cloud" },
    { value: 2, label: "Web Development" },
    { value: 3, label: "iOS" },
    { value: 4, label: "Android" },
    { value: 5, label: "Unity" },
    { value: 6, label: "Digital Marketing" },
  ];

  const genders = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
  ];

  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [objectif, setObjectif] = React.useState<string>("");
  const [situation, setSituation] = React.useState<string>("");
  const [selectedInterests, setSelectedInterests] = React.useState<
    { value: number; label: string }[]
  >([]);

  const [country, setCountry] = React.useState<CountryType>({
    value: "",
    label: "",
    iso: "",
  });
  const [countryState, setCountryState] = React.useState<string>("");

  const [countryList, setCountryList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);

  const [gender, setGender] = React.useState<string>("");
  const [isStateDisabled, setIsStateDisabled] = React.useState(true);

  const [phoneCountry, setPhoneCountry] = React.useState<string>("");

  const [isRegisterClicked, setIsRegisterClicked] =
    React.useState<boolean>(false);

  const [isCountryValid, setIsCountryValid] = React.useState<boolean>(true);

  const studentPlan = userStore.userData?.plan == "student";

  useEffect(() => {
    if (userStore?.user !== null) {
      navigate("/");
    } else if (!userStore?.userData) {
      navigate("/register");
    }
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return {
            value: country.c_id,
            label: country.name,
            iso: country.iso2.toLowerCase(),
            phoneCode: country.phone_code,
          };
        }
      );

      setCountryList(countries);

      if (studentPlan) {
        setCountry({
          value: "",
          label: userStore.userData?.country || "",
          iso: "",
        });
        onCountryChange(userStore.userData?.country || "", countries);
        setCountryState(userStore.userData?.state || "");
        setPhoneNumber(userStore.userData?.phone || "");
        setGender(userStore.userData?.gender || "");
        setObjectif(userStore.userData?.goal || "");
        setSituation(userStore.userData?.situation || "");
      }
    }
  }, []);

  const register = async () => {
    const interests = selectedInterests.map((item) => item.label);

    const user = {
      email: userStore?.userData?.email?.trim() || "",
      fullName: userStore?.userData?.fullName || "",
      password: userStore?.userData?.password || "",
      state: countryState,
      goal: objectif,
      country: country.label,
      governorate: countryState,
      phone: phoneNumber,
      gender: gender,
      situation: situation,
      interest: interests,
      step: 1,
    };

    userStore
      .register(user)
      .then((token) => {
        console.log("token", token);

        if (token && token !== null) {
          userStore.setUserData({});
          if (courseStore.selectedCourse) {
            navigate(`/payment/${courseStore.selectedCourse}`);
          } else {
            toast.success(
              "Your account is created successfully. A verification link has been sent to you via Email."
            );
            setTimeout(() => {
              setIsRegisterClicked(false);
              navigate("/login");
            }, 5000);
          }
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log("errdfd", err);

        setIsRegisterClicked(false);
        if (err.response && err.response.data) {
          if (
            err.response.data.error.message.includes("Email already exists")
          ) {
            toast.error("Email already exists");
          } else {
            toast.error("Something went wrong!");
          }
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const navigateToChoosePlan = () => {
    if (userStore.userData) {
      const user = userStore.userData;
      userStore.setUserData({
        ...user,
        phone: phoneNumber,
        state: countryState,
        country: country.label,
        gender: gender,
        goal: objectif,
        situation: situation,
      });
    }
    setIsRegisterClicked(false);
    navigate("/choose-plan");
  };

  const onChange = (selectedItem: any) => {
    setSelectedInterests(selectedItem);
  };

  const handleContinue = () => {
    if (!country.label) {
      setIsCountryValid(false);
      toast.error("Please select a country.");
      return;
    }

    setIsRegisterClicked(true);
    userStore.userData?.plan == "student" ? register() : navigateToChoosePlan();
  };

  const onCountryChange = (selectedCountryId: string, countryList: any[]) => {
    setCountryState("");
    setStateList([]);
    setIsStateDisabled(true);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setCountry(selectedCountry);
      setPhoneCountry(selectedCountry.iso);
      setPhoneNumber(`+${selectedCountry.phoneCode} `);
      setIsCountryValid(true);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setStateList(mappedCountryStatesList);
        setIsStateDisabled(false);
      }
    }
  };

  const onStateChange = (stateId: string) => {
    let statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      let statesList = JSON.parse(statesListFromLocalStorage);
      let selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setCountryState(selectedState.name);
    }
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />

      <div className={styles.left_column}>
        <img
          loading="lazy"
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className={styles.right_column_content}>
          <Link className={styles.back_button} to="/">
            <img
              src="/images/icons/arrow-left.svg"
              alt="Arrow Left"
              width={5}
              height={10}
            />
            <span className={styles.back_text}>Home</span>
          </Link>

          <span className={styles.title}>Let&apos;s get started!</span>
          <span className={styles.sub_title}>Hop back in!</span>

          <div className={styles.form}>
            <div className="w-full flex justify-start items-center space-x-4">
              <RSelect
                required={true}
                label="Country"
                value={country.label}
                options={countryList}
                onChange={(e) => onCountryChange(e.target.value, countryList)}
              />
              <RSelect
                label="State"
                value={countryState}
                options={stateList}
                onChange={(e) => onStateChange(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-start items-center space-x-4">
              <div className="w-full">
                <RSelect
                  label="Gender"
                  value={gender}
                  options={genders}
                  onChange={(e) => setGender(e.target.value)}
                />
              </div>
              <div className="w-full">
                <PhoneInput
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                  inputStyle={{
                    padding: "1.4rem 0.8rem 1.4rem 0.8rem",
                    fontSize: "0.8rem",
                    color: "black",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    border: "1px solid #CAD4E0",
                    width: "100%",
                  }}
                />
              </div>
            </div>

            <RSelect
              label="Situation"
              value={situation}
              options={situations}
              onChange={(e) => setSituation(e.target.value)}
            />
            <RSelect
              label="Objectif"
              value={objectif}
              options={objectifs}
              onChange={(e) => setObjectif(e.target.value)}
            />

            {studentPlan && (
              <Select
                isMulti
                options={options}
                value={selectedInterests}
                closeMenuOnSelect={false}
                onChange={(e) => onChange(e)}
                placeholder="Select your interests"
              />
            )}

            <div className={styles.button_container}>
              <Button
                type="secondary"
                text="Back"
                onClick={() => navigate(-1)}
                customStyle={{ flex: 1 }}
              />

              <Button
                type="primary"
                text="Continue"
                onClick={() => handleContinue()}
                customStyle={{ flex: 1 }}
                isDisabled={isRegisterClicked || !country.label}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default observer(CompleteRegister);
